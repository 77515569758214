import React from 'react'
import styled from 'styled-components'
import CTA from '../components/CTA'
import ContentItem from '../components/ContentItem'
import WhiteBox from '../components/WhiteBox'
import { PADDING_STANDARD, BREAKPOINTS } from '../components/tokens'
import { ContentBlock as ContentBlockType, ToggledContentBlock as ToggledContentBlockType } from '../types/wordpress'

const Spacing = styled.div`
    margin-top: ${PADDING_STANDARD}px;
`

const SpacingTop = styled.div`
    padding-top: 24px;
`

const SpaceAround = styled.div`
    padding: 24px 16px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        padding: 24px;
    }
`

const IntroStyle = styled.div`
    > p {
        font-family: Bryant, sans-serif;
        font-size: 18px;
        line-height: 1.2;
        margin: 0px;
        font-weight: 600;
        letter-spacing: -0.0125rem;
    }
`
const StatsStyle = styled.div`
    @media (max-width: ${BREAKPOINTS.tablet}px) {
        > h2 {
            text-align: center;
            line-height: 30px;
        }
    }
    > p {
        font-family: Bryant, sans-serif;
        text-align: center;
        font-size: 18px;
    }
`

const ContentBlock = ({
    block: { content, callToAction, currentUrl, params },
    children,
    id,
    className,
    isList,
    isIntro,
    areStats,
}: {
    block: ContentBlockType
    children?: React.ReactNode
    id?: string
    className?: string
    isList?: boolean
    isIntro?: boolean
    areStats?: boolean
}) => {
    if (content.length === 0 && !children) {
        return null
    }

    let renderedBlock

    if (content.length && children) {
        if (isIntro) {
            renderedBlock = (
                <>
                    <Spacing />
                    <WhiteBox className={className} noPadding>
                        <SpaceAround>
                            <IntroStyle>{content.map(ContentItem)}</IntroStyle>
                        </SpaceAround>
                        {children}
                    </WhiteBox>
                </>
            )
        } else if (areStats) {
            renderedBlock = (
                <>
                    <Spacing />
                    <WhiteBox className={className} noPadding>
                        <SpaceAround>
                            <StatsStyle>{content.map(ContentItem)}</StatsStyle>
                        </SpaceAround>
                        {children}
                    </WhiteBox>
                </>
            )
        } else {
            renderedBlock = (
                <>
                    <Spacing />
                    <WhiteBox className={className} noPadding>
                        <SpaceAround>{content.map(ContentItem)}</SpaceAround>
                        {children}
                    </WhiteBox>
                </>
            )
        }
    } else if (!content.length && children) {
        renderedBlock = (
            <>
                <Spacing />
                <WhiteBox className={className} noPadding>
                    {!isList ? <SpacingTop>{children}</SpacingTop> : children}
                </WhiteBox>
            </>
        )
    } else if (isIntro) {
        renderedBlock = (
            <>
                <Spacing />
                <WhiteBox className={className} noPadding>
                    <SpaceAround>
                        <IntroStyle>{content.map(ContentItem)}</IntroStyle>
                    </SpaceAround>
                </WhiteBox>
            </>
        )
    } else if (areStats) {
        renderedBlock = (
            <>
                <Spacing />
                <WhiteBox className={className} noPadding>
                    <SpaceAround>
                        <StatsStyle>{content.map(ContentItem)}</StatsStyle>
                    </SpaceAround>
                </WhiteBox>
            </>
        )
    } else {
        renderedBlock = (
            <>
                <Spacing />
                <WhiteBox className={className} noPadding>
                    <SpaceAround>{content.map(ContentItem)}</SpaceAround>
                </WhiteBox>
            </>
        )
    }

    return (
        <section id={id}>
            {renderedBlock}
            {callToAction.enabled ? (
                <>
                    <Spacing />
                    <CTA
                        copy={callToAction.copy}
                        headline={callToAction.headline}
                        pictogram={callToAction.pictogram}
                        actionUrl={callToAction.actionUrl}
                        actionLabel={callToAction.actionLabel}
                        currentUrl={currentUrl}
                        params={params}
                    />
                </>
            ) : null}
        </section>
    )
}

export const ToggledContentBlock = ({
    block,
    children,
    id,
    className,
    isList,
    isIntro,
    areStats,
}: {
    block: ToggledContentBlockType
    children?: React.ReactNode
    id?: string
    className?: string
    isList?: boolean
    isIntro?: boolean
    areStats?: boolean
}) =>
    block.enabled
        ? ContentBlock({ block: block.contentBlock, children, id, className, isList, isIntro, areStats })
        : null

export default ContentBlock
